import { observer } from 'mobx-react-lite';
import React from 'react';

import { Header } from '@goodfynd/react-web.layout.header';

import config from '../../../config';
import { useApp, useAppDispatch } from '../../../context/AppContext';
import { useStores } from '../../../stores';
import { MAIN_NAV } from '../../layout/Header/header-links';
import type { CmsHeaderProps } from './types';

export default observer(function CmsHeader({
  logo_type = 'events',
  ...props
}: CmsHeaderProps): JSX.Element {
  const { eventId, isDarkMode, toggleDarkMode, unsetEvent } = useStores();
  const { user, isLoggedIn } = useApp();
  const { getLoginUrl, logout } = useAppDispatch();

  const navLinks = MAIN_NAV(user, eventId, isLoggedIn);
  return (
    <Header
      {...props}
      isAuthenticated={isLoggedIn}
      userFullName={user.fullName}
      userInitials={user.initials?.replace(' ', '')}
      getLoginUrl={getLoginUrl}
      isDarkMode={isDarkMode}
      logoEnvironment={config.env.NAME_LOWER}
      logoType={logo_type}
      logout={() => {
        unsetEvent();
        logout();
      }}
      navLinks={navLinks}
      toggleDarkMode={toggleDarkMode}
    />
  );
});
