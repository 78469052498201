import type { PropsWithChildren } from 'react';
import React from 'react';

import { StyledHubspotContainer } from '@goodfynd/react-web.layout.hubspot-container/styles';

import { StyledPageContainer } from './styles';

export default function LayoutFull({
  children,
}: PropsWithChildren<unknown>): JSX.Element {
  return (
    <StyledHubspotContainer>
      <StyledPageContainer>{children}</StyledPageContainer>
    </StyledHubspotContainer>
  );
}
